import style from './AnketaSend.module.scss';

type ModalProps = {
  open: boolean;
  eng: boolean;
  error: boolean;
};

export const AnketaSend = ({ open, eng, error }: ModalProps) => {
  const err = 'Ошибка отправки данных(Error data)!'
  const ru = 'Оправлено!'
  const en = 'Submitted!'

  if (!open) return null;
  return (
    <div className={style.main}>
      <div className={style.content}>
        <div className={style.text}>{error ? err : eng ? en : ru}</div>
        <div className={style.buttons}>
          <button className={style.btn} type="button" onClick={() => window.location.reload()}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
