import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { getAnketas } from 'store/reducers/anketa/ActionCreators';
import style from './Anketas.module.scss';


function Anketas() {
	const dispatch = useAppDispatch();
	const { objectData: anketaGet } = useAppSelector((state) => state.anketaReducer.anketaGet);
	const password = 'b3q1y8BxTgXfBQE';
	const [pass, setPass] = useState(false);
	const [tmp, setTmp] = useState('');

	const handleChange = (event: any) => {
		const result = event.target.value;
		setTmp(result.toString());
	}

	const handleClick = () => {
		if (tmp === password) {
			setPass(true);
		} else {
			console.log('wrong password')
		}
	}

	useEffect(() => {
		if (pass === true) {
			dispatch(getAnketas());
		}
		// eslint-disable-next-line
  }, [pass]);

	return (
		<div className={style.main}>
			{pass ? (
				<table className={style.table}>
				<thead>
					<tr className={style.headtr}>
						<td className={style.heademptys}>№</td>
						<td className={style.headtd}>Наименование организации</td>
						<td className={style.headtd}>Сфера деятельности</td>
						<td className={style.headtd}>Информационный ресурс</td>
						<td className={style.headtd}>Локация</td>
						<td className={style.heademptye}></td>
					</tr>
				</thead>
				<tbody>
					{anketaGet.data.map((item) => (
						<>
						<tr className={style.bodytr} key={item.id}>
							<td className={style.bodyemptys}>{item.id}</td>
							<td className={style.bodytd}>{item.form_organizations} {item.organization}</td>
							<td className={style.bodytd}>{item.sphere_activity}</td>
							<td className={style.bodytd}>{item.web_resource}</td>
							<td className={style.bodytd}>{item.country}</td>
							<td className={style.bodyemptye}></td>
						</tr>
						<details>
							<summary></summary>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>ИНН</td>
								<td className={style.bodycontents}>{item.inn_organizations}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>ОКВЭД</td>
								<td className={style.bodycontents}>{item.okvad_organizations}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>Статус</td>
								<td className={style.bodycontents}>{item.client_status}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>ФИО</td>
								<td className={style.bodycontents}>{item.client}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>Телефон</td>
								<td className={style.bodycontents}>+7-{item.phone}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>Почта</td>
								<td className={style.bodycontents}>{item.email}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>Сколько лет на рынке</td>
								<td className={style.bodycontents}>{item.company_age}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontent}>
								<td className={style.bodyemptys}></td>
								<td className={style.bodycontent}>Общая сумма контрактов за последние 3 года:</td>
								<td className={style.bodycontents}>{item.sum_contracts}</td>
								<td className={style.bodyemptye}></td>
							</tr>
							<tr className={style.bodytrcontentjs}>
								<td className={style.bodyemptysjs}></td>
								<td className={style.bodycontentjs}>Ссылки на муниципальные контракты за последние 12 мес.</td>
								<td className={style.bodycontentsjs}>
									<div>{item.web_contracts_one}</div>
									<div>{item.web_contracts_two}</div>
									<div>{item.web_contracts_three}</div>
								</td>
								<td className={style.bodyemptyejs}></td>
							</tr>
							<tr className={style.bodytrcontentjs}>
								<td className={style.bodyemptysjs}></td>
								<td className={style.bodycontentjs}>Компетенции внутри компании</td>
								<td className={style.bodycontentsjs}>
									<div>{item.competencies[1]}</div>
									<div>{item.competencies[2]}</div>
									<div>{item.competencies[3]}</div>
									<div>{item.competencies[4]}</div>
									<div>{item.competencies[5]}</div>	
								</td>
								<td className={style.bodyemptyejs}></td>
							</tr>
						</details>
					</>
					))}
				</tbody>
			</table>
			) : (
				<div className={style.pass}>
					<div className={style.contents}>
        		<div className={style.texts}>Введите пароль!</div>
						<div className={style.inp}>
							<input className={style.inps} onChange={handleChange} type="text" />
						</div>
        		<div className={style.buttonss}>
          		<button className={style.btns} type="button" onClick={handleClick}>
          			OK
          		</button>
        		</div>
      </div>
				</div>
			)}
			
		</div>
	);
};

export default Anketas;
