import { AxiosResponse } from 'axios';
import { AnketaResponse, PostResponse } from 'response/AnketaResponse';
import { instancePublic } from './instances';

export const getAnketaAPI = {
  async anketas_get(): Promise<AxiosResponse<AnketaResponse>> {
    return instancePublic.get<AnketaResponse>('/forms/ankets', {});
  },
};

export const postAnketaAPI = {
  async anketas_post(
		client: string,
		client_status: string,
		organization: string,
		inn_organizations: string,
		form_organizations: string,
		country: string,
		region: string,
		city: string,
		sphere_activity: string,
		okvad_organizations: string,
		web_resource: string,
		company_age: number,
		phone: string,
		email: string,
		sum_contracts: string,
		web_contracts_one: string,
		web_contracts_two: string,
		web_contracts_three: string,
		competencies: Record<string, any>,
		confirm: boolean,
	): Promise<AxiosResponse<PostResponse>> {
    return instancePublic.post<PostResponse>('/forms/ankets', {
			client,
			client_status,
			organization,
			inn_organizations,
			form_organizations,
			country,
			region,
			city,
			sphere_activity,
			okvad_organizations,
			web_resource,
			company_age,
			phone,
			email,
			sum_contracts,
			web_contracts_one,
			web_contracts_two,
			web_contracts_three,
			competencies,
			confirm,
		});
  },
};
