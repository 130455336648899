import * as axios from 'axios';

// import store from 'store/store';

import baseURL from './config';

export const instancePublic = axios.default.create({
  // withCredentials: true,
  baseURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const instancePrivate = axios.default.create({
  // withCredentials: true,
  baseURL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
