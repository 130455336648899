import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { postAnketas } from 'store/reducers/anketa/ActionCreators';
import { AnketaSend } from './AnketaSend';
import style from './Anketa.module.scss';

type FormValues = {
	client: string;
	client_status: string;
	organization: string;
	inn_organizations: string;
	form_organizations: string;
	country: string;
	region: string;
	city: string;
	sphere_activity: string;
	okvad_organizations: string;
	web_resource: string;
	company_age: number;
	phone: string;
	email: string;
	sum_contracts: string;
	web_contracts_one: string;
	web_contracts_two: string;
	web_contracts_three: string;
	competencies: Record<string, any>;
	confirm: boolean;
}

function AnketaEng() {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, watch } = useForm<FormValues>({ mode: 'onChange' });
  const [step, setStep] = useState(1);
  const [linkStep, setLinkStep] = useState(1);
  const [work, setWork] = useState<string[]>([]);
  const [checking, setChecking] = useState(false);
  const [send, setSend] = useState(false);
  const [inn, setInn] = useState('');
  const [okvad, setOkvad] = useState('');
  const [phones, setPhones] = useState('');
  const [err, setErr] = useState(false);
  // eslint-disable-next-line
  const urlRegex = new RegExp("^(https?|ftp):\/\/([a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})(:[0-9]+)?(\/.*)?)$");
  // eslint-disable-next-line
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const nextStepOne = () => {
    if (
        watch('client') && 
        watch('client_status') && 
        watch('organization') && 
        watch('inn_organizations').length >= 10 && 
        watch('form_organizations') && 
        watch('country') &&
        watch('region') &&
        watch('city')
      ) {
      setStep(step + 1);
    } else {
      return null;
    }
  };

  const nextStepTwo = () => {
    if (watch('sphere_activity') && 
        watch('okvad_organizations').length >=2 && 
        urlRegex.test(watch('web_resource')) && 
        watch('company_age') &&
        watch('phone').length >= 11 && 
        emailRegex.test(watch('email'))) {
      setStep(step + 1);
    } else {
      return null;
    }
  };

  const finalStep = (event: any) => {
    if (linkStep === 1) {
      if (!watch('sum_contracts') || !urlRegex.test(watch('web_contracts_one'))) {
        event.preventDefault(); // Предотвращаем стандартное действие кнопки submit
        return null;
      }
    } else if (linkStep === 2) {
      if (!watch('sum_contracts') || 
        !urlRegex.test(watch('web_contracts_one')) || 
        !urlRegex.test(watch('web_contracts_two'))) {
          event.preventDefault();
          return null;
      }
    } else {
      if (
        !watch('sum_contracts') || 
        !urlRegex.test(watch('web_contracts_one')) ||
        !urlRegex.test(watch('web_contracts_two')) || 
        !urlRegex.test(watch('web_contracts_three'))) {
          event.preventDefault();
          return null;
      }
    }
  };

  const lastStep = () => {
    setStep(step - 1);
    setChecking(false);
  };

  const linkNextStep = () => {
    if (linkStep !== 3) {
      setLinkStep(linkStep + 1);
    } else {
      return null;
    }
  };

  const handleChangeWork = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = event.target.defaultValue;
    if (event.target.checked) {
      setWork([...work, result]);
    } else {
      const index = work.indexOf(result);
        if (index > -1) {
          const updatedWork = [...work];
          updatedWork.splice(index, 1);
          setWork(updatedWork);
        }
    }
  };

  const jsonWorks: { [key: number]: string } = {};
  work.forEach((value: string, index: number) => {
    jsonWorks[index + 1] = value;
  });

  const jsonResultWorks = JSON.stringify(jsonWorks);
  const parsedJsonResultWorks: Record<string, any> = JSON.parse(jsonResultWorks);

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = event.target.value;
    event.target.value = result.slice(0, 12);
    setInn(result.slice(0, 12));
  };

  const handleChangeOkvad = (event: React.ChangeEvent<HTMLInputElement>) => {
    const point = '.';
    let result = event.target.value.replace(/\D/g, ''); // Удаляем все нечисловые символы
    let formattedValue = '';

    for (let i = 0; i < result.length; i++) {
        formattedValue += result[i];
        if ((i + 1) % 2 === 0 && i !== result.length - 1) {
            formattedValue += point;
        }
    }

    event.target.value = formattedValue.slice(0, 8);
    setOkvad(formattedValue.slice(0, 8));
  };

  const handleChangePhones = (event: React.ChangeEvent<HTMLInputElement>) => {
    const prefix = '+7-';
    let result = event.target.value.replace(/\D/g, '');

    if (!result.startsWith(prefix)) {
      result = result.startsWith('7') ? prefix + result.slice(1) : prefix + result;
    }

    event.target.value = result.slice(0, 13); // Ограничиваем длину номера до 12 символов
    setPhones(result.slice(3, 13));
  };

  const handleChangeSpace = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result = event.target.value.replace(/\s/g, '');
    event.target.value = result;
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const result = await dispatch(
        postAnketas(
          data.client,
          data.client_status,
          data.organization,
          data.inn_organizations = inn,
          data.form_organizations,
          data.country,
          data.region,
          data.city,
          data.sphere_activity,
          data.okvad_organizations = okvad,
          data.web_resource,
          data.company_age,
          data.phone = phones,
          data.email,
          data.sum_contracts,
          data.web_contracts_one,
          data.web_contracts_two,
          data.web_contracts_three,
          data.competencies = parsedJsonResultWorks,
          data.confirm = checking,
        ),
      );
      if (result?.status !== 200) {
        setErr(true);
      }
      setSend(true);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.main}>
      {step === 1 && (
        <div className={style.content}>
          <div className={style.blockOne}>
            <div className={style.text}>1. Full name:</div>
            <label htmlFor="fio" className={style.enter}>
              <input
                className={!watch('client') ? style.inpE : style.inp}
                id="fio"
                type="text"
                maxLength={120}
                placeholder="Full name"
                {...register('client')}
              />
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>2. Status:</div>
            <label htmlFor="status" className={style.enter}>
              <select
                className={!watch('client_status') ? style.inpE : style.inp}
                id="status"
                {...register('client_status')}
              >
                <option></option>
                <option value={'Owner'}>Owner</option>
                <option value={'Director'}>Director</option>
                <option value={'Head of Department'}>Head of Department</option>
                <option value={'Manager'}>Manager</option>
              </select>
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>3. Name of the organization:</div>
            <label htmlFor="organization" className={style.enter}>
              <input
                className={ !watch('organization') ? style.inpE : style.inp}
                id="organization"
                type="text"
                maxLength={120}
                placeholder="Your organization"
                {...register('organization')}
              />
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>4. EIN/NIN/ITN/LEI:</div>
            <label htmlFor="inn_organizations" className={style.enter}>
              <input
                className={ 
                  !watch('inn_organizations') || watch('inn_organizations').length < 10  ? style.inpE : style.inp
                }
                id="inn_organizations"
                type="number"
                placeholder="012345678910"
                onInput={handleChangeLimit}
                {...register('inn_organizations')}
              />
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>5. Form of ownership:</div>
            <label htmlFor="org" className={style.enter}>
              <select
                className={!watch('form_organizations') ? style.inpE : style.inp}
                id="org"
                {...register('form_organizations')}
              >
                <option></option>
                <option>Individual entrepreneur</option>
                <option>Limited liability company</option>
                <option>Joint stock company</option>
              </select>
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>6. Country:</div>
            <label htmlFor="country" className={style.enter}>
              <input
                className={!watch('country') ? style.inpE : style.inp}
                id="country"
                type="text"
                maxLength={200}
                placeholder="Enter your county"
                {...register('country')}
              />
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>7. Region:</div>
            <label htmlFor="region" className={style.enter}>
              <input
                className={!watch('region') ? style.inpE : style.inp}
                id="region"
                type="text"
                maxLength={200}
                placeholder="Enter your region"
                {...register('region')}
              />
            </label>
          </div>
          <div className={style.blockOne}>
            <div className={style.text}>8. City:</div>
            <label htmlFor="city" className={style.enter}>
              <input
                className={!watch('city') ? style.inpE : style.inp}
                id="city"
                type="text"
                maxLength={200}
                placeholder="Enter your city"
                {...register('city')}
              />
            </label>
          </div>
          <div className={style.buttons}>
            <button className={style.btn} type="button">Back</button>
            <button className={style.btns} type="button" onClick={nextStepOne}>Next</button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className={style.content}>
          <div className={style.block}>
            <div className={style.text}>9. Sphere of activity:</div>
            <label htmlFor="sphera" className={style.enter}>
              <select
                className={!watch('sphere_activity') ? style.inpE : style.inp}
                id="sphera"
                {...register('sphere_activity')}
              >
                <option></option>
                <option value={'Systems integrator'}>Systems integrator</option>
                <option value={'Traffic light maintenance'}>Traffic light maintenance</option>
                <option value={'Road construction'}>Road construction</option>
                <option value={'Production of equipment and signsProduction of equipment and signs'}>
                  Production of equipment and signs
                </option>
                <option value={'Installation and maintenance of video surveillance'}>
                  Installation and maintenance of video surveillance
                </option>
                <option value={'Another'}>Another</option>
              </select>
            </label>
          </div>
          <div className={style.block}>
            <div className={style.text}>10. The code of the main type of Economic Activities:</div>
            <label htmlFor="okvad" className={style.enter}>
              <input
                className={ 
                  !watch('okvad_organizations') || watch('okvad_organizations').length < 2 ? style.inpE : style.inp
                }
                id="okvad"
                type="text"
                placeholder="00.00.00"
                onInput={handleChangeOkvad}
                {...register('okvad_organizations')}
              />
            </label>
          </div>
          <div className={style.block}>
            <div className={style.text}>11. Web resource:</div>
            <label htmlFor="web" className={style.enter}>
              <input
                className={
                  !watch('web_resource') || !urlRegex.test(watch('web_resource')) ? style.inpE : style.inp
                }
                id="web"
                type="url"
                maxLength={120}
                onInput={handleChangeSpace}
                placeholder="https://example.com"
                {...register('web_resource')}
              />
            </label>
          </div>
          <div className={style.block}>
            <div className={style.text}>12. The company's age:</div>
            <label htmlFor="age" className={style.enter}>
              <select
                className={!watch('company_age') ? style.inpE : style.inp}
                id="age"
                {...register('company_age')}
              >
                <option></option>
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
              </select>
            </label>
          </div>
          <div className={style.block}>
            <div className={style.text}>13. Phone:</div>
            <label htmlFor="phone" className={style.enter}>
              <input
                className={ !watch('phone') || watch('phone').length < 11 ? style.inpE : style.inp}
                id="phone"
                type="text"
                onInput={handleChangePhones}
                placeholder="+7-0000000"
                {...register('phone')}
              />
            </label>
          </div>
          <div className={style.block}>
            <div className={style.text}>14. Email:</div>
            <label htmlFor="email" className={style.enter}>
              <input
                className={
                  !watch('email') || !emailRegex.test(watch('email')) ? style.inpE : style.inp
                }
                id="email"
                type="email"
                maxLength={120}
                onInput={handleChangeSpace}
                placeholder="example@email.com"
                {...register('email')}
              />
            </label>
          </div>
          <div className={style.buttons}>
            <button className={style.btn} type="button" onClick={lastStep}>Back</button>
            <button className={style.btns} type="button" onClick={nextStepTwo}>Next</button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className={style.content}>
        <div className={style.block}>
          <div className={style.text}>15. The total amount of municipal contracts for the last 3 years:</div>
          <label htmlFor="summa" className={style.enter}>
            <select
              className={!watch('sum_contracts') ? style.inpE : style.inp}
              id="summa"
              {...register('sum_contracts')}
            >
              <option></option>
              <option value={'Less than 5 million rubles'}>Less than 5 million rubles</option>
              <option value={'From 5 to 10 million rubles'}>From 5 to 10 million rubles</option>
              <option value={'From 10 to 30 million rubles'}>From 10 to 30 million rubles</option>
              <option value={'From 30 to 50 million rubles'}>From 30 to 50 million rubles</option>
              <option value={'From 50 million rubles and above'}>From 50 million rubles and above</option>
            </select>
          </label>
        </div>
        <div className={style.blocks}>
          <div className={style.text}>16. Add links to municipal contracts for the last 12 months:</div>
          <div className={style.enter}>
            <div className={style.linkscontent}>
              <input
                className={
                  !watch('web_contracts_one') || !urlRegex.test(watch('web_contracts_one')) ? style.inpsE : style.inps
                }
                type="url"
                onInput={handleChangeSpace}
                placeholder="https://example.com"
                {...register('web_contracts_one')}
              />
              <button className={style.btn} type="button" onClick={linkNextStep}>+</button>
            </div>
            <div className={style.linkscontent}>
              <input
                className={
                  !watch('web_contracts_two') || !urlRegex.test(watch('web_contracts_two')) ? style.inpsE : style.inps
                }
                style={linkStep < 2 ? { display: 'none' } : undefined}
                type="url"
                onInput={handleChangeSpace}
                placeholder="https://example.com"
                {...register('web_contracts_two')}
              />
            </div>
            <div className={style.linkscontent}>
              <input
                className={
                  !watch('web_contracts_three') || !urlRegex.test(watch('web_contracts_three')) ? style.inpsE : style.inps
                }
                style={linkStep < 3 ? { display: 'none' } : undefined}
                type="url"
                onInput={handleChangeSpace}
                placeholder="https://example.com"
                {...register('web_contracts_three')}
              />
            </div>
          </div>
        </div>
        <div className={style.block}>
          <div className={style.text}>17. Competencies within the company:</div>
          <div className={style.enter}>
            <div className={style.boxes}>
              <div className={style.up}>
                <label htmlFor="engineer">
                    <input
                      className={style.ups}
                      id="engineer"
                      type="checkbox"
                      onChange={handleChangeWork}
                      defaultValue={'Operation Engineer'}
                    />
                    Operation Engineer
                  </label>
                  <label htmlFor="buyer">
                    <input
                      className={style.ups}
                      id="buyer"
                      type="checkbox"
                      onChange={handleChangeWork}
                      defaultValue={'Public procurement specialists'}
                    />
                    Public procurement specialists
                  </label>
              </div>
              <div className={style.up}>
                <label htmlFor="montage">
                  <input
                    className={style.ups}
                    id="montage"
                    type="checkbox"
                    onChange={handleChangeWork}
                    defaultValue={'Installers'}
                  />
                  Installers
                </label>
                <label htmlFor="sysadmin">
                  <input
                    className={style.ups}
                    id="sysadmin"
                    type="checkbox"
                    onChange={handleChangeWork}
                    defaultValue={'System Administrators'}
                  />
                  System Administrators
                </label>
              </div>
              <div className={style.up}>
                <label htmlFor="pto">
                  <input className={style.ups} id="pto" type="checkbox" onChange={handleChangeWork} defaultValue={'PTO'} />
                  PTO
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={style.block}>
          <div className={style.text}>I agree to the processing of personal data:</div>
          <label htmlFor="check" className={style.checks}>
            <input
              className={style.check}
              id="check"
              type="checkbox"
              onClick={() => setChecking(!checking)}
            />
          </label>
        </div>
        <div className={style.buttons}>
          <button className={style.btn} type="button" onClick={lastStep}>Back</button>
          {checking ? ( 
            <button className={style.btns} type="submit" onClick={finalStep}>Submit for review</button>
          ) : (
            <button className={style.btnsoff} type="button">Confirm your agree!</button>
          )
        }
        </div>
      </div>
      )}
      <AnketaSend open={send} eng={true} error={err} />
      <NavLink className={style.lang} to="/anketa">EN &gt; RU</NavLink>
    </form>
  );
};

export default AnketaEng;
