import { Routes, Route, Navigate } from 'react-router-dom';
import Anketa from 'pages/Anketa';
import AnketaEng from 'pages/AnketaEng';
import Anketas from 'pages/Anketas';
import style from './MainApp.module.scss';

function MainApp() {
	const path = true;
	
	return (
		<div className={style.main}>
			<Routes>
				{path ? <Route path="/" element={<Navigate to="/anketa" replace />} /> : null}
				<Route path="/anketa/*" element={<Anketa />} />
				<Route path="/anketa/anketaEng" element={<AnketaEng />} />
				<Route path="/anketas" element={<Anketas />} />
			</Routes>
		</div>
	);
};

export default MainApp;
