import { AppDispatch } from 'store/store';
import { getAnketaAPI, postAnketaAPI } from 'api/api';
import { anketaSlice } from './AnketaSlice';

export const getAnketas = () => async (dispatch: AppDispatch) => {
	dispatch(anketaSlice.actions.anketaFetchingGet());
	try {
		const result = await getAnketaAPI.anketas_get();
		dispatch(anketaSlice.actions.anketaFetchingGetSucces(result.data));
	} catch (e) {
		console.error(e.response?.data?.message);
		console.error(e.message);
		dispatch(anketaSlice.actions.anketaFetchingGetError(e.message));
	}
};

export const postAnketas = 
	(
		client: string,
		client_status: string,
		organization: string,
		inn_organizations: string,
		form_organizations: string,
		country: string,
		region: string,
		city: string,
		sphere_activity: string,
		okvad_organizations: string,
		web_resource: string,
		company_age: number,
		phone: string,
		email: string,
		sum_contracts: string,
		web_contracts_one: string,
		web_contracts_two: string,
		web_contracts_three: string,
		competencies: Record<string, any>,
		confirm: boolean,
	) => 
	async (dispatch: AppDispatch) => {
		dispatch(anketaSlice.actions.anketaFetchingPost());
		try {
			const result = await postAnketaAPI.anketas_post(
				client,
				client_status,
				organization,
				inn_organizations,
				form_organizations,
				country,
				region,
				city,
				sphere_activity,
				okvad_organizations,
				web_resource,
				company_age,
				phone,
				email,
				sum_contracts,
				web_contracts_one,
				web_contracts_two,
				web_contracts_three,
				competencies,
				confirm,
			);
			dispatch(anketaSlice.actions.anketaFetchingPostSucces(result.data));
			return result;
		} catch (e) {
			console.error(e.response?.data?.message);
			console.error(e.message);
			dispatch(anketaSlice.actions.anketaFetchingPostError(e.message));
		}
	};

	export const setResetState = () => async (dispatch: AppDispatch) => {
		dispatch(anketaSlice.actions.anketaReset());
	};
