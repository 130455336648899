import { combineReducers, configureStore } from '@reduxjs/toolkit';
import anketaReducer from './reducers/anketa/AnketaSlice';

const rootReducer = combineReducers({
  anketaReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
const store = setupStore();
export default store;
