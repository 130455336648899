import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnketaResponse, PostResponse } from 'response/AnketaResponse';

type IAnketa<T> = {
	isInit: boolean;
	isFetching: boolean;
	error: string;
	objectData: T;
}

type IAnketaState = {
	anketaGet: IAnketa<AnketaResponse>;
	anketaPost: IAnketa<PostResponse>;
}

const initialState: IAnketaState = {
	anketaGet: {
		isInit: false,
		isFetching: false,
		error: '',
		objectData: {
			data: [],
		},
	},
	anketaPost: {
		isInit: false,
		isFetching: false,
		error: '',
		objectData: {
			client: '',
			client_status: '',
			organization: '',
			inn_organizations: '',
			form_organizations: '',
			country: '',
			region: '',
			city: '',
			sphere_activity: '',
			okvad_organizations: '',
			web_resource: '',
			company_age: 0,
			phone: '',
			email: '',
			sum_contracts: '',
			web_contracts_one: '',
			web_contracts_two: '',
			web_contracts_three: '',
			competencies: {},
			confirm: false,
		},
	},
}

export const anketaSlice = createSlice({
	name: 'anketa',
	initialState,
	reducers: {
		// start
		anketaFetchingGet(state) {
			state.anketaGet.isFetching = true;
		},
		anketaFetchingPost(state) {
			state.anketaPost.isFetching = true;
		},
		// succes
		anketaFetchingGetSucces(state, action: PayloadAction<AnketaResponse>) {
			state.anketaGet.isInit = true;
			state.anketaGet.isFetching = false;
			state.anketaGet.error = '';
			state.anketaGet.objectData.data = action.payload.data;
		},
		anketaFetchingPostSucces(state, action: PayloadAction<PostResponse>) {
			state.anketaPost = {
				isInit: true,
				isFetching: false,
				error: '',
				objectData: action.payload,
			};
		},
		// error
		anketaFetchingGetError(state, action: PayloadAction<string>) {
			state.anketaGet.isFetching = false;
			state.anketaGet.error = action.payload;
		},
		anketaFetchingPostError(state, action: PayloadAction<string>) {
			state.anketaPost.isFetching = false;
			state.anketaGet.error = action.payload;
		},
		// reset state
		anketaReset() {
			return initialState;
		},
	},
});

export default anketaSlice.reducer;
